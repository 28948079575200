import React from 'react';
import s from './Zagolovok.module.css'

const Zagolovok = ({textH1,textH2,textH3,textH4,textH5, showLine=true}) => {

    return (
        <div className={s.wrap}>
            {textH1 &&<h1 className={s.h1}>{textH1}</h1>}
            {textH2 &&<h2 className={s.h2}>{textH2}</h2>}
            {textH3 &&<h3 className={s.h3}>{textH3}</h3>}
            {textH4 &&<h4 className={s.h4}>{textH4}</h4>}
            {showLine &&            <div className={s.lines}>
                <div className={s.line}></div>
                <div className={s.line}></div>
            </div>}
        </div>
        )
}
export default Zagolovok;
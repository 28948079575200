import React from 'react'
import s from "./Protivopokazaniya.module.css"


const Protivopokazaniya = ({protiv1,protiv2,protiv3,protiv4,protiv5,protiv6,protiv7,protiv8,protiv9,protiv10,protiv11,protiv12,protiv13,protiv14,protiv15,}) => {
    return <div>
        <div className={s.wrap}>
            <div className={`${s.spisok} ${s.column3}`}>
                    {protiv1 && <li>{protiv1}</li>}
                    {protiv2 && <li>{protiv2}</li>}
                    {protiv3 && <li>{protiv3}</li>}
                    {protiv4 && <li>{protiv4}</li>}
                    {protiv5 && <li>{protiv5}</li>}
                    {protiv6 && <li>{protiv6}</li>}
                    {protiv7 && <li>{protiv7}</li>}
                    {protiv8 && <li>{protiv8}</li>}
                    {protiv9 && <li>{protiv9}</li>}
                    {protiv10 && <li>{protiv10}</li>}
                    {protiv11 && <li>{protiv11}</li>}
                    {protiv12 && <li>{protiv12}</li>}
                    {protiv13 && <li>{protiv13}</li>}
                    {protiv14 && <li>{protiv14}</li>}
                    {protiv15 && <li>{protiv15}</li>}
            </div>
        </div>
    </div>
}

export default Protivopokazaniya;



import React, { useEffect } from 'react';
import s from "../TablePrice/TablePrice.module.css";

const TablePrice = ({data,caption}) => {

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const tableBackground = document.getElementById('table-background');
            if (tableBackground) {
                tableBackground.style.transform = `translateY(${scrollY * 0.5}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return <div>
        <div className={s.table__Background} id="table-background"></div>
        <table>
            <caption className={s.caption}>{caption}</caption>
            <thead>
            <tr>
                <th >Зона</th>
                <th >Время</th>
                <th >Цена</th>
            </tr>
            </thead>
            <tbody>
            {data.map(item => (
                <tr  key={item.id}>
                    <td >{item.name}</td>
                    <td >{item.time}</td>
                    <td >{item.price}</td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export default TablePrice;
import React from 'react'
import s from "./Procedure.module.css";
import Discription from "../Discription/Discription";
import SliderDoPosle from "../SliderDoPosle/SliderDoPosle";





const Procedure = ({nameProcedure,opisanie,plus1,plus2,plus3,plus4}) => {
    return <div >
        <div><Discription textH1={nameProcedure} textH3={opisanie} plus1={plus1} plus2={plus2} plus3={plus3} plus4={plus4}></Discription></div>


    </div>
}

export default Procedure;
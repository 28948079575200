import React from 'react'
import s from "./TablePasses.module.css"

const TablePasses = ({caption,data}) => {
    return<>
        <div className={s.table__Background} id="table-background"></div>
        <table>
            <caption className={s.caption}>{caption}</caption>
            <thead>
            <tr>
                <th>Зона</th>
                <th>4 Посещения (-15%)</th>
                <th>6 Посещений (-20%)</th>
                <th>10 Посещений (-30%)</th>
            </tr>
            </thead>
            <tbody>
            {data.map(item => (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.price4}</td>
                    <td>{item.price6}</td>
                    <td>{item.price10}</td>
                </tr>
            ))}
            </tbody>
        </table>
    </>
}
export default TablePasses;
import React, {useState} from "react";
import s from './NavButtonZapis.module.css'
import { useNavigate } from 'react-router-dom';

const NavButtonZapis = ({ openModal ,buttonText,targetComponent,isModal}) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isModal) {
            // Открываем модальное окно
            openModal();
        } else {
            // Переходим к целевой компоненте
            navigate(targetComponent);
        }
    };

    return <div>
        <button onClick={handleButtonClick} className={s.button}>
            <div>{buttonText}</div>
        </button>
    </div>

}

export default NavButtonZapis;

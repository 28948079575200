import React from 'react'
import s from "./Discounts.module.css"



const Discounts = () => {
    return <div>

    </div>
}

export default Discounts;



import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import s from './SliderDoPosle.module.css'
import { Autoplay, Pagination, Navigation,Grid,Controller } from 'swiper/modules';
import Doposle from "../DoPosle/Doposle";


const SliderDoPosle = ({imageDo1,imagePosle1,imageDo2,imagePosle2,imageDo3,imagePosle3,imageDo4,imagePosle4,countProcedur1,countProcedur2,countProcedur3,countProcedur4}) => {
    return (
        <div className={s.wrap}>
            <Swiper
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                breakpoints={{

                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation,Grid,Controller]}
                pagination={true
                }
                style={{
                    "--swiper-pagination-color": "#ec70e8",
                    "--swiper-navigation-color": "#ec70e8",
                }}
                navigation={true}
                loop={true}
                className={s.swiper}
            >
                <SwiperSlide className={s.swiperSlide}><Doposle image1={imageDo1} image2={imagePosle1} countProcedur={countProcedur1}></Doposle></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Doposle image1={imageDo2} image2={imagePosle2} countProcedur={countProcedur2}></Doposle></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Doposle image1={imageDo3} image2={imagePosle3} countProcedur={countProcedur3}></Doposle></SwiperSlide>
                <SwiperSlide className={s.swiperSlide}><Doposle image1={imageDo4} image2={imagePosle4} countProcedur={countProcedur4}></Doposle></SwiperSlide>
            </Swiper>
        </div>
    );
};

export default SliderDoPosle;
import React from 'react';
import s from './Discription.module.css'

const Discription = ({textH1,textH2,textH3,textP,textH4,plus4,plus3,plus2,plus1, showLine=true}) => {

    return (
        <div className={s.wrap}>
            {textH1 && <h1 className={s.h1}>{textH1}</h1>}
            {textH3 && <h3 className={s.h3}>{textH3}</h3>}
            {textP && <p>{textP}</p>}
            <div className={s.spisok}>
                {plus1 && <li>{plus1}</li>}
                {plus2 && <li>{plus2}</li>}
                {plus3 && <li>{plus3}</li>}
                {plus4 && <li>{plus4}</li>}
            </div>


            {textH4 && <h4 className={s.h4}>{textH4}</h4>}
            {showLine &&            <div className={s.lines}>
                <div className={s.line}></div>
                <div className={s.line}></div>
            </div>}
        </div>
        )
}
export default Discription;
import React from 'react'
import s from './Passes.module.css'

import TablePasses from "../TablePasses/TablePasses";


const Passes = () => {
    const abon = [
        { id:1, name: 'Подмышки', price4: '1770₽', price6: '2360₽',price10: '3540₽' },
        { id:2, name: 'Бикини глубокое', price4: '3570₽', price6: '4760₽',price10: '7140₽' },
        { id:3, name: 'Голени', price4: '7170₽', price6: '9560₽',price10: '14340₽' },
        { id:4, name: 'Руки', price4: '4170₽', price6: '5560₽',price10: '8340₽' },
        { id:5, name: 'Верхняя губа', price4: '1470₽', price6: '1960₽',price10: '2940₽' },
        { id:6, name: 'Подбородок', price4: '1470₽', price6: '1960₽',price10: '2940₽' },
        { id:7, name: 'Набор N1', price4: '4470₽', price6: '5960₽',price10: '8940₽' },
        { id:8, name: 'Набор N2', price4: '8070₽', price6: '10760₽',price10: '16140₽' },
        { id:9, name: 'Набор N3', price4: '11070₽', price6: '14760₽',price10: '22140₽' },
        { id:10, name: 'Набор N4', price4: '14070₽', price6: '18760₽',price10: '28140₽' },
        { id:11, name: 'Набор N5', price4: '17070₽', price6: '22760₽',price10: '34140₽' },
    ];
    return <div>
        <TablePasses caption={"АБОНЕМЕНТЫ"} data={abon} ></TablePasses>
    </div>
}

export default Passes;
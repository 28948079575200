import React from 'react'
import s from "./Set.module.css";
import NavButtonZapis from "../Buttons/navButtonZapis/NavButtonZapis";


const Set = ({N,p1,p2,p3,p4,p5,p6,price,setImage,openModal,buttonText,isModal,targetComponent}) => {
    return <div>
        <div className={s.container}>
            <div className={s.wrapText}>
                <div className={s.circle}>
                    <img src={setImage} />
                </div>
                <div className={s.contH1}>
                    <h1 className={s.h1}>{N}</h1>
                    <p>{p1}</p>
                    <p>{p2}</p>
                    <p>{p3}</p>
                    <p>{p4}</p>
                    <p>{p5}</p>
                    <p>{p6}</p>
                </div>
                {/*<h4>{p1}</h4>*/}
                {/*<h3>{p2}</h3>*/}
                {/*<h3>{p3}</h3>*/}
                {/*<h3>{p4}</h3>*/}
                {/*<h3>{p5}</h3>*/}
                {/*<h3>{p6}</h3>*/}
                <h1 className={s.h1}>{price}</h1>
            </div>
            <div  className={s.button}>
                <NavButtonZapis isModal={isModal} openModal={openModal} buttonText={buttonText} targetComponent={targetComponent}></NavButtonZapis>
            </div>

        </div>

    </div>
}

export default Set;

import {Link, useMatch} from "react-router-dom";
import s from './CustomLink.module.css'

const CustomLink = ({children, to, ...props}) => {
    const match = useMatch(to);

    return (
        <Link to={to}
              style={{
                  color : match ? '#ec70e8' : '#5c5a5b',
                  textDecoration: 'none',
              }}
              {...props}
            className={s.myLink}
        >
            {children}
        </Link>
    )

}

export default CustomLink;
import React, { useState, useEffect } from 'react';
import s from './Preloader.module.css'
import Lottie from "lottie-react";
import animData from '../../logo2preload.json'


const Preloader = () => {
    return <>
        <Lottie animationData={animData}/>
    </>
}

export default Preloader;
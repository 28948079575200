import React, {useState} from "react";
import s from './Content.module.css'

import Zagolovok from "../Zagolovok/Zagolovok";

import Set from "../Set/Set";

import setImage2 from "../../image/Set7.png";
import setImage3 from "../../image/Set3.png";
import setImage5 from "../../image/Set6.png";
import Modal from "../Modal/Modal";

import Contacts from "../Contacts/Contacts";



const Content = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };
    return <div className={s.content}>
        <div>
            <Zagolovok textH1={"Добро пожаловать"} textH2={"в LaserQueen лазерная эпиляция Красноярск"} textH3={"Преображенский, ул. Петра Ломако, 8"} textH4={"Лицензия № ЛО41-01019-24/01205328"}/>
        </div>

        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>

        <div className={s.wrapSet}>
            <div>
                <Set N={"Лазерная эпиляция"} p1={"Диодный лазер"} p2={"Александритовый лазер"} setImage={setImage5} buttonText={"Подробнее"} targetComponent={"/laserepil"}></Set>
            </div>
            <div>
                <Set N={"Эндосфера-терапия"} p1={"аппаратная методика коррекции целлюлита"} setImage={setImage3} buttonText={"Подробнее"} targetComponent={"/endosfera"} isModal={false}></Set>
            </div>
        </div>
        <div><Contacts/></div>

    </div>
}

export default Content;
import React, { useState, useEffect } from 'react';

import s from "./Doposle.module.css"
const Doposle = ({countProcedur,image1,image2,showOne=true}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={s.doposle}>
            {countProcedur && <div><p>Количество процедур:{countProcedur}</p></div>}
            <div className={s.wrap}>
                <div className={s.blockImage}>
                    <img src={image1} className={s.image} />
                </div>
                <div>
                    <img src={image2} className={s.image}/>
                </div>
            </div>
        </div>
    );
};

export default Doposle;

// style={{ maxWidth: windowWidth / 2, height: 'auto' }}